// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-guru-ravidass-birth-anniversary-dates-jsx": () => import("./../../../src/pages/guru-ravidass-birth-anniversary-dates.jsx" /* webpackChunkName: "component---src-pages-guru-ravidass-birth-anniversary-dates-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-list-of-ravidassia-temples-in-the-world-jsx": () => import("./../../../src/pages/list-of-ravidassia-temples-in-the-world.jsx" /* webpackChunkName: "component---src-pages-list-of-ravidassia-temples-in-the-world-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

